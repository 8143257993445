import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import {
  GoogleAdsTransformedSizesItem,
  GoogleAdsSlot,
  GoogleSlotExternal,
  Callbacks,
  GoogleAdsTransformedBidders,
  Transformed,
} from 'BaxterScript/types/Slot';
import { Config, GoogleAdsProviderConfig, GoogleAdsProviderConfigSettings } from 'BaxterScript/types/Config';
import {
  GoogleAdsApsConfig,
  GoogleAdsConfig,
  GoogleAdsPathConfig,
  GoogleAdsPrebidConfig,
  GoogleAdsSizesConfig,
} from 'BaxterScript/types/ProviderSettings/GoogleAds';
import { transformTargeting } from 'BaxterScript/version/web/provider/TransformTargeting';
import * as Strings from 'BaxterScript/helper/string/String';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import {
  googleImpressionViewableCallback,
  googleSlotRenderEndedCallback,
} from 'BaxterScript/version/web/provider/googleads/GoogleAdsEventsCallbacksV2';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import * as Objects from 'BaxterScript/helper/object/Object';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';
import BiddersV2 from './bidders/GoogleAdsBiddersV2';

export const id = Providers.GOOGLE_ADS;

export const webpackExclude = (config: Config): boolean =>
  !(
    Object.values(config.slots.provider?._ ?? {}).includes(id) ||
    Object.values(config.slots.provider ?? {}).includes(id)
  ) || !baxterV2Enabled(config);

export const init = () => {
  console.info('[SLOTS][GOOGLEADS][INIT]');
  globalThis.googletag = globalThis.googletag || { cmd: [] };
  globalThis.googletag.cmd = globalThis.googletag.cmd || [];

  if (BiddersV2) {
    BiddersV2.init();
  }
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEADS][DEPENDENCIES]');
  const dependencyList = [
    {
      id: 'gpt',
      url: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
    },
  ];
  if (BiddersV2) {
    dependencyList.push(...BiddersV2.dependencies());
  }
  return {
    id,
    dependencies: dependencyList,
  };
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEADS][LOADED]');
  const providerConfig = globalThis.Baxter.config.providers[id] || {};
  // @ts-ignore
  const googleSettings: Record<string, unknown> = providerConfig.settings || {};
  const { googletag } = globalThis;
  console.debug('[SLOTS][GOOGLEADS][LOADED]', googletag, providerConfig);
  if (googletag) {
    googletag.cmd.push(() => {
      try {
        if (Strings.isString(googleSettings.adsenseBackgroundColor)) {
          console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().set');
          googletag.pubads().set('adsense_background_color', googleSettings.adsenseBackgroundColor);
        }
        if (googleSettings.collapseEmptyDivs === 'after') {
          console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().collapseEmptyDivs');
          googletag.pubads().collapseEmptyDivs();
        }
        if (googleSettings.collapseEmptyDivs === 'before') {
          console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().collapseEmptyDivs true');
          googletag.pubads().collapseEmptyDivs(true);
        }
        console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().enableSingleRequest');
        googletag.pubads().enableSingleRequest();
        console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().disableInitialLoad');
        googletag.pubads().disableInitialLoad();
        console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.enableServices');
        googletag.enableServices();
      } catch (e) {
        console.error('[SLOTS][GOOGLEADS][LOADED]', e);
        newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
          command: '[LOADED]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADS][LOADED] googletag not defined`);
    newRelicMetrics.reportError(NewRelicError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[LOADED]' });
  }

  if (BiddersV2) {
    BiddersV2.loaded();
  }
};

export const consent = (restrictDataProcessing: boolean): void => {
  console.info('[SLOTS][GOOGLEADS][CONSENT]', restrictDataProcessing);
  const { googletag } = globalThis;
  if (googletag) {
    googletag.cmd.push(() => {
      try {
        googletag.pubads().setPrivacySettings({ restrictDataProcessing });
      } catch (err) {
        console.error(`[SLOTS][GOOGLEADS][CONSENT]`, err);
        newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
          command: '[CONSENT]',
          message: (err as Error).message,
        });
        throw err;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADS][CONSENT] googletag not defined`);
    newRelicMetrics.reportError(NewRelicError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[CONSENT]' });
    throw new Error('[SLOTS][GOOGLEADS][CONSENT] googletag not defined');
  }
};

const setSlotSizes = (sizes: GoogleAdsTransformedSizesItem[], external: GoogleSlotExternal) => {
  console.info('[SLOTS][GOOGLEADS][SETSLOTSIZES]', sizes, external);
  const { googletag } = globalThis;
  const map = googletag.sizeMapping();
  sizes.forEach((size) => {
    if (size.viewport) {
      map.addSize(size.viewport, size.slot || []);
    }
  });
  const mapping = map.build();
  external.defineSizeMapping(mapping);
};

const setSlotTargeting = (keyValues: TargetingParams, external: GoogleSlotExternal) => {
  console.info('[SLOTS][GOOGLEADS][SETSLOTTARGETING]', keyValues, external);
  const { googletag } = globalThis;
  for (const key of Object.keys(keyValues)) {
    const val = keyValues[key];
    const valueToCheck = googletag.pubads().getTargeting(key);
    if (valueToCheck.length === 0) {
      external.setTargeting(key, val);
    } else if (valueToCheck.length === 1 && !Array.isArray(val) && valueToCheck[0] !== val) {
      external.setTargeting(key, val);
    } else if (Array.isArray(val) && val.filter((x) => valueToCheck.includes(x)).length !== val.length) {
      external.setTargeting(key, val);
    }
  }
};

export const transform = (
  pageId: string,
  containerId: string,
  slotId: string,
  params: TargetingParams
): Transformed<GoogleAdsSlot> => {
  console.info('[SLOTS][GOOGLEADS][TRANSFORM]', pageId, containerId, slotId, params);
  const slotConfig = globalThis.Baxter.config.slots?.providerSettings?.[id] as GoogleAdsConfig;
  const providerConfig = globalThis.Baxter.config.providers[id];

  let targeting = {};
  if (slotConfig?.targeting) {
    targeting = globalThis.Baxter.context.configurationService.getById(
      slotConfig?.targeting || {},
      pageId,
      containerId,
      slotId
    );
  }
  const transformedTargeting = {
    targeting: {},
  } as Partial<GoogleAdsSlot>;
  transformTargeting(transformedTargeting, slotConfig, providerConfig.settings, pageId, containerId, slotId, params);

  let path = {};
  let transformedPath = '';
  if (slotConfig?.path) {
    const { accountId } = providerConfig.settings;
    const pathPrefixMap = providerConfig.settings.pathPrefix;
    const pathPrefix = Strings.parseMap(pathPrefixMap || [], params);
    path = globalThis.Baxter.context.configurationService.getById(slotConfig?.path, pageId, containerId, slotId) || {};
    const parsedPath = Strings.parseMap((path as GoogleAdsPathConfig).map || [], params);
    transformedPath = pathPrefix ? `/${accountId}/${pathPrefix}/${parsedPath}` : `/${accountId}/${parsedPath}`;
  }

  let sizes = {};
  let transformedSizes: GoogleAdsTransformedSizesItem[] = [];
  if (slotConfig?.sizes) {
    const defaultSizes = [{ viewport: [1, 1], slot: [[1, 1]] }];
    sizes =
      globalThis.Baxter.context.configurationService.getById(slotConfig?.sizes, pageId, containerId, slotId) || {};
    transformedSizes = ((sizes as GoogleAdsSizesConfig).map || defaultSizes)
      .map((size) => {
        // eslint-disable-next-line no-param-reassign
        if (!size.slot || size.slot.length === 0) size.slot = defaultSizes[0].slot;
        size.slot.forEach((item, index) => {
          if (Strings.isString(item) && item.includes('x')) {
            const widthHeight = item.split('x');
            const isIntegers = widthHeight.every((element) => Strings.isNumeric(element));
            if (isIntegers) {
              // eslint-disable-next-line no-param-reassign
              size.slot[index] = widthHeight.map((element) => parseInt(element, 10));
            }
          }
        });
        return size;
      })
      .reverse();
  }

  let prebid = {} as GoogleAdsPrebidConfig;
  if (slotConfig?.prebid) {
    prebid =
      globalThis.Baxter.context.configurationService.getById(slotConfig?.prebid, pageId, containerId, slotId) || {};
  }

  let aps = {} as GoogleAdsApsConfig;
  if (slotConfig?.aps) {
    aps = globalThis.Baxter.context.configurationService.getById(slotConfig?.aps, pageId, containerId, slotId) || {};
  }

  const biddersConfig = { prebid, aps };

  let bidders = {} as GoogleAdsTransformedBidders;
  if (BiddersV2 && BiddersV2.enabledSomeBidderForSlot(biddersConfig)) {
    bidders = BiddersV2.transform(biddersConfig);
  }

  return {
    [id]: {
      providerConfig,
      config: {
        sizes,
        path,
        targeting,
        ...biddersConfig,
      },
      transformed: {
        sizes: transformedSizes,
        path: transformedPath,
        targeting: transformedTargeting.targeting as TargetingParams,
        bidders,
      },
      state: {},
    },
  };
};

const googleCreate = (googleSlot: GoogleAdsSlot, callbacks: Callbacks): void => {
  const { googletag } = globalThis;
  if (googletag) {
    googletag.cmd.push(() => {
      try {
        console.debug(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot}`);
        const { containerId, pageId, innerId, id: slotId } = googleSlot;
        const sizes = googleSlot[id].transformed.sizes?.[0]?.slot;
        const external: GoogleSlotExternal = googletag.defineSlot(googleSlot[id].transformed.path, sizes, innerId);
        if (external) {
          // eslint-disable-next-line no-param-reassign
          googleSlot[id].state.external = external;
          console.debug(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id} external.addService`);
          external.addService(googletag.pubads());
          setSlotSizes(googleSlot[id].transformed.sizes || [], external);
          setSlotTargeting(googleSlot[id].transformed.targeting || {}, external);
          googletag
            .pubads()
            .addEventListener(
              'slotRenderEnded',
              googleSlotRenderEndedCallback(
                googleSlot,
                external,
                { ad_unit_id: googleSlot[id].transformed.path },
                callbacks.slotRenderEndedCallback
              )
            );
          googletag
            .pubads()
            .addEventListener(
              'impressionViewable',
              googleImpressionViewableCallback(
                googleSlot,
                external,
                { ad_unit_id: googleSlot[id].transformed.path },
                callbacks.impressionViewableCallback
              )
            );
        } else {
          console.error(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id} could not get external`);
          newRelicMetrics.reportError(NewRelicError.GOOGLEADS_NO_EXTERNAL, {
            providerId: id,
            slotId,
            containerId,
            pageId,
            path: googleSlot[id].transformed.path,
          });
          throw new Error('[SLOTS][GOOGLEADS] could not get external');
        }
      } catch (e) {
        console.error(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id}`, e);
        newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
          command: '[CREATE]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id} googletag not defined`);
    newRelicMetrics.reportError(NewRelicError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[CREATE]' });
    throw new Error('[SLOTS][GOOGLEADS][CREATE] googletag not defined');
  }
};

export const create = (slot: GoogleAdsSlot, callbacks: Callbacks): void => {
  console.info('[SLOTS][GOOGLEADS][CREATE]', slot);
  if (BiddersV2 && BiddersV2.enabledSomeBidderForSlot(slot[id].config)) {
    console.debug(`[SLOTS][GOOGLEADS][CREATE] ${slot.containerId} ${slot.id} Bidders.create`);
    BiddersV2.create(slot, googleCreate, callbacks);
  } else {
    console.debug(`[SLOTS][GOOGLEADS][CREATE] ${slot.containerId} ${slot.id} Google.create`);
    googleCreate(slot, callbacks);
  }
};

const googleLoad = (slots: GoogleAdsSlot[]): void => {
  console.debug('[SLOTS][GOOGLEADS][GOOGLELOAD] googletag.pubads().refresh(...)', slots);
  const { googletag } = globalThis;
  if (googletag) {
    const slotsToRefresh = slots.map((slot) => {
      const { external } = slot[id].state;
      if (external) {
        setSlotTargeting(
          {
            ad_request_source: slot[id].state.loadSource,
          },
          external
        );
      }
      return external;
    });
    if (slotsToRefresh.length) {
      googletag.pubads().refresh(slotsToRefresh);
    }
  } else {
    console.error('[SLOTS][GOOGLEADS][GOOGLELOAD] googletag not defined');
    newRelicMetrics.reportError(NewRelicError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[GOOGLELOAD]' });
    throw new Error('[SLOTS][GOOGLEADS][GOOGLELOAD] googletag not defined');
  }
};

export const load = async (source: string, slots: GoogleAdsSlot[] = []): Promise<void> => {
  console.info('[SLOTS][GOOGLEADS][LOAD]', slots);
  const { googletag } = globalThis;
  if (googletag) {
    googletag.cmd.push(() => {
      try {
        console.debug('[SLOTS][GOOGLEADS][LOAD] slots.filter(...)', slots);
        const slotsToLoad = slots
          .filter((slot) => slot[id].state.external)
          .filter((slot) => {
            if (slot[id].state.alreadyRemoved) {
              console.debug('[SLOTS][GOOGLEADS][GOOGLELOAD] slot already removed', slot);
              newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_SLOT_ALREADY_REMOVED);
              return false;
            }
            return true;
          })
          .map((slot) => {
            // eslint-disable-next-line no-param-reassign
            slot[id].state.loadSource = source;
            return slot;
          });
        if (BiddersV2 && slotsToLoad.some((slot) => BiddersV2.enabledSomeBidderForSlot(slot[id].config))) {
          console.debug(`[SLOTS][GOOGLEADS][CREATE] Bidders.load`);
          BiddersV2.load(slotsToLoad, googleLoad);
        } else {
          console.debug(`[SLOTS][GOOGLEADS][CREATE] Google.load`);
          googleLoad(slotsToLoad);
        }
      } catch (e) {
        console.error('[SLOTS][GOOGLEADS][LOAD]', e);
        newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
          command: '[LOAD]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADS][LOAD] googletag not defined`);
    newRelicMetrics.reportError(NewRelicError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[LOAD]' });
    throw new Error('[SLOTS][GOOGLEADS][LOAD] googletag not defined');
  }
};

export const remove = (slots: GoogleAdsSlot[] = []): void => {
  console.info('[SLOTS][GOOGLEADS][REMOVE]', slots);
  const { googletag } = globalThis;
  if (googletag) {
    if (BiddersV2) {
      console.debug('[SLOTS][GOOGLEADS][REMOVE] Bidders.remove', slots);
      BiddersV2.remove(slots);
    }
    googletag.cmd.push(() => {
      try {
        console.debug('[SLOTS][GOOGLEADS][REMOVE] googletag.destroySlots', slots);
        const slotsToDestroy = slots
          .map((slot) => {
            // eslint-disable-next-line no-param-reassign
            slot[id].state.alreadyRemoved = true;
            return slot;
          })
          .filter((slot) => slot[id].state.external)
          .map((slot) => slot[id].state.external);
        if (slotsToDestroy.length) {
          const destroyed = googletag.destroySlots(slotsToDestroy);
          console.debug(`[SLOTS][GOOGLEADS][REMOVE] googletag.destroySlots() result ${destroyed}`);
          if (!destroyed) {
            newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_DESTROY_SLOTS_IN_REMOVE_DESTROYED_NOTHING);
          }
        }
      } catch (e) {
        console.error('[SLOTS][GOOGLEADS][REMOVE]', e);
        newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
          command: '[REMOVE]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADS][REMOVE] googletag not defined`);
    newRelicMetrics.reportError(NewRelicError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[REMOVE]' });
    throw new Error('[SLOTS][GOOGLEADS][REMOVE] googletag not defined');
  }
};

export const setPageTargeting = (params: TargetingParams): void => {
  console.info('[SLOTS][GOOGLEADS][SETPAGETARGETING]');
  const providerSettings = (globalThis.Baxter.config.providers[id] || {}) as GoogleAdsProviderConfig;
  const googleSettings = (providerSettings.settings || {}) as GoogleAdsProviderConfigSettings;
  const { googletag } = globalThis;
  if (googletag) {
    googletag.cmd.push(() => {
      try {
        console.debug('[SLOTS][GOOGLEADS][SETPAGETARGETING] googletag.pubads().clearTargeting');
        googletag.pubads().clearTargeting();
        console.info('[SLOTS][GOOGLEADS][SETPAGETARGETING]', googleSettings.targeting);
        if (Array.isArray(googleSettings.targeting)) {
          const ranges = globalThis.Baxter.config.app?.ranges;
          const targeting = Objects.parseMap(googleSettings.targeting || [], params, ranges) as TargetingParams;
          Object.keys(targeting).forEach((key) => globalThis.googletag.pubads().setTargeting(key, targeting[key]));
          if (BiddersV2) {
            BiddersV2.setPageTargeting(targeting);
          }
        }
      } catch (e) {
        console.error('[SLOTS][GOOGLEADS][SETPAGETARGETING]', e);
        newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
          command: '[SETPAGETARGETING]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADS][SETPAGETARGETING] googletag not defined`);
    newRelicMetrics.reportError(NewRelicError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[SETPAGETARGETING]' });
    throw new Error('[SLOTS][GOOGLEADS][SETPAGETARGETING] googletag not defined');
  }
};
