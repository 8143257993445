import * as Html from 'BaxterScript/helper/browser/Html';
import * as State from 'BaxterScript/version/web/core/State';
import * as ProviderV2 from 'BaxterScript/version/web/core/ProviderV2';
import StyleV2 from 'BaxterScript/version/web/feature/StyleV2';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { Callbacks, Slot } from 'BaxterScript/types/Slot';
import { Config } from 'BaxterScript/types/Config';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import ninjaMetrics from 'BaxterScript/helper/metrics/NinjaMetrics';
import { NinjaMetric } from 'BaxterScript/helper/metrics/NinjaMetric';
import PlaceholderV2 from 'BaxterScript/version/web/feature/PlaceholderV2';
import { calculateElapsedTime } from 'BaxterScript/helper/time/TimeElapsedEvaluate';
import LazyLoadV2 from 'BaxterScript/version/web/feature/LazyLoadV2';
import TimerRefreshV2 from 'BaxterScript/version/web/feature/TimerRefreshV2';
import FallbackV2 from 'BaxterScript/version/web/feature/FallbackV2';
import * as Objects from 'BaxterScript/helper/object/Object';
import * as Condition from 'BaxterScript/helper/query/Condition';
import AutoplayV2 from 'BaxterScript/version/web/feature/AutoplayV2';
import StickyV2 from 'BaxterScript/version/web/feature/StickyV2';
import { ContainerType } from 'BaxterScript/types/ContainerType';
import { Providers as ProviderIds } from 'BaxterScript/version/web/config/Providers';
import { Features } from 'BaxterScript/version/web/config/Features';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';

export const webpackExclude = (config: Config): boolean => !baxterV2Enabled(config);

const getConditionParams = (refreshCount: number, params: TargetingParams = {}) =>
  Objects.clone({
    ...State.getUser(),
    refreshCount,
    ...params,
  });

const removeSlots = (containers: Record<string, ContainerType>): void => {
  console.info('[SLOTS][CONTAINER][REMOVE]', containers);
  const removedSlots = ProviderV2.remove(Object.values(containers).map((container) => container.state.slot!));
  removedSlots.forEach((slot) => {
    if (LazyLoadV2) {
      LazyLoadV2.remove(containers[slot.containerId].state.htmlElement as HTMLElement, slot);
    }
    if (StickyV2) {
      StickyV2.remove(containers[slot.containerId].state.htmlElement as HTMLElement, slot);
    }
    if (AutoplayV2) {
      AutoplayV2.remove(slot);
    }
    if (TimerRefreshV2) {
      TimerRefreshV2.remove(slot);
    }
    // eslint-disable-next-line no-param-reassign
    containers[slot.containerId].state.slot = undefined;
    Html.hide(slot.innerHtmlElement);
    if (StyleV2) {
      StyleV2.removeClass(slot.innerHtmlElement, slot.pageId, slot.containerId, slot.id);
    }
    if (PlaceholderV2) {
      PlaceholderV2.apply(slot.pageId, containers[slot.containerId].config, Objects.clone(State.getPageParams()));
    }
  });
};

const findSlot = (
  pageId: string,
  container: ContainerType,
  params: TargetingParams,
  refreshCount: number
): string | undefined =>
  container.config.slots
    .filter((slotId) => !FallbackV2 || (FallbackV2 && !FallbackV2.alreadyApplied(container, slotId)))
    .find((slotId) =>
      Condition.query(
        globalThis.Baxter.context.configurationService.getRuleCondition(pageId, container.config.id, slotId),
        getConditionParams(refreshCount, params)
      )
    );

export const clear = (containers: Record<string, ContainerType>): void => {
  removeSlots(containers);
  Object.keys(containers).forEach((containerId) => {
    const container = Html.getElementById(containerId);
    if (container) {
      Html.clear(container);
      Html.clearInlineStyles(container);
    }
    const containerAfter = Html.getElementById(`${containerId}-after`);
    if (containerAfter) {
      Html.clear(containerAfter);
      Html.clearInlineStyles(containerAfter);
    }
  });
};

const noMatchingSlot = (pageId: string, containerId: string, source: string) => {
  console.debug(`[SLOTS][CONTAINER][SET] ${pageId} ${containerId} NO MATCHING SLOT`);
  newRelicMetrics.reportMetric(NewRelicMetric.CONTAINER_NO_MATCHING_SLOT, { source });
};

const containerDivNotFound = (
  containerId: string,
  pageId: string,
  slotId: string,
  params: TargetingParams,
  source: string
) => {
  console.error(`[SLOTS][CONTAINER][SET] ${containerId} CONTAINER NOT FOUND`);
  newRelicMetrics.reportError(NewRelicError.CONTAINER_DIV_NOT_FOUND, {
    pageId,
    containerId,
    slotId,
    params,
    source,
  });
};

const setInner = (containerHtmlElement: HTMLElement, innerId: string) => {
  const inner = document.createElement('div');
  inner.id = innerId;
  Html.addClass(inner, 'baxter-inner');
  containerHtmlElement.appendChild(inner);
  Html.addClass(containerHtmlElement, 'baxter-container');
  Html.show(containerHtmlElement);
  return inner;
};

const containerInnerDivNotFound = (
  containerId: string,
  pageId: string,
  slotId: string,
  newSlot: Partial<Slot>,
  params: TargetingParams,
  source: string
) => {
  console.error(`[SLOTS][CONTAINER][SET] ${containerId} INNER NOT FOUND`);
  newRelicMetrics.reportError(NewRelicError.CONTAINER_INNER_DIV_NOT_FOUND, {
    pageId,
    containerId,
    slotId,
    innerId: newSlot.innerId,
    params,
    source,
  });
};

const createCallbacks = (
  container: ContainerType,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
): Callbacks => ({
  impressionViewableCallback: (slotId: string, source: string, parameters: Record<string, unknown>) => {
    console.info('[SLOTS][CONTAINER][IMPRESSIONVIEWABLECALLBACK]', parameters);
    ninjaMetrics.reportMetric(NinjaMetric.ADVERTISEMENT_VIEWED, {
      ...parameters,
      ad_slot_id: slotId,
      ad_request_source: source,
    });
    newRelicMetrics.reportMetric(NewRelicMetric.AD_IMPRESSION, { slotId, source });
  },
  slotRenderEndedCallback: (
    source: string,
    slot: Slot,
    isEmpty: boolean,
    hasVideo?: boolean,
    parameters: Record<string, unknown> = {}
  ): boolean => {
    console.info('[SLOTS][CONTAINER][SLOTRENDERENDEDCALLBACK]', source, slot, isEmpty, hasVideo, parameters);
    let creationTillRenderedTime;
    if (!slot.alreadyRendered) {
      // eslint-disable-next-line no-param-reassign
      slot.alreadyRendered = true;
      creationTillRenderedTime = calculateElapsedTime(slot.creationDate!);
    }
    newRelicMetrics.reportMetric(NewRelicMetric.AD_RENDERED, {
      pageId: slot.pageId,
      containerId: slot.containerId,
      slotId: slot.id,
      isEmpty,
      providerId: slot.provider,
      creationTillRenderedTime,
      lazyLoad: slot?.[Features.LAZY_LOAD]?.config?.enabled,
      source,
    });
    if (isEmpty) {
      if (FallbackV2 && FallbackV2.apply(container, slot, setSlot)) {
        return true;
      }
      if (hasVideo) {
        Html.show(slot.innerHtmlElement);
        if (PlaceholderV2) {
          PlaceholderV2.remove(slot.pageId, slot.containerId);
        }
        if (StickyV2) {
          StickyV2.apply(container.state.htmlElement as HTMLElement, slot);
        }
        if (AutoplayV2) {
          AutoplayV2.apply(slot);
        }
      }
    } else {
      ninjaMetrics.reportMetric(NinjaMetric.ADVERTISEMENT_DISPLAYED, {
        ...parameters,
        ad_slot_id: slot.id,
        ad_request_source: source,
      });
      Html.show(slot.innerHtmlElement);
      if (PlaceholderV2) {
        PlaceholderV2.remove(slot.pageId, slot.containerId);
      }
      if (StickyV2) {
        StickyV2.apply(container.state.htmlElement as HTMLElement, slot);
      }
      if (AutoplayV2) {
        AutoplayV2.apply(slot);
      }
      if (TimerRefreshV2) {
        TimerRefreshV2.apply(container, slot, setSlot);
      }
    }
    return false;
  },
});

export const setSlot = (source: string, pageId: string, container: ContainerType, params: TargetingParams): boolean => {
  try {
    console.info('[SLOTS][CONTAINER][SET]', source, pageId, container);
    newRelicMetrics.reportMetric(NewRelicMetric.CONTAINER_SET_SLOT, { source });

    const existingSlot = container.state.slot;
    const containerId = container.config.id;
    const refreshCount = existingSlot?.refreshCount || 0;
    const slotId = findSlot(pageId, container, params, refreshCount);
    // eslint-disable-next-line no-param-reassign
    params.slot = slotId;

    if (!slotId) {
      noMatchingSlot(pageId, containerId, source);
      return false;
    }

    const containerHtmlElement = Html.getElementById(containerId);
    if (!containerHtmlElement) {
      if (existingSlot) {
        removeSlots({ [containerId]: container });
      }
      containerDivNotFound(containerId, pageId, slotId, params, source);
      return false;
    }
    if (!container.state.htmlElement) {
      // eslint-disable-next-line no-param-reassign
      container.state.htmlElement = containerHtmlElement;
    }

    const providerId = globalThis.Baxter.context.configurationService.getSlotProvider(
      pageId,
      containerId,
      slotId
    ) as ProviderIds;
    if (slotId !== existingSlot?.id) {
      if (existingSlot) {
        removeSlots({ [containerId]: container });
      }
      const newSlot: Partial<Slot> | undefined = ProviderV2.transform(providerId, pageId, containerId, slotId, params);
      if (!newSlot) {
        return false;
      }
      newSlot.id = slotId;
      newSlot.pageId = pageId;
      newSlot.containerId = containerId;
      newSlot.innerId = `${containerId}-inner`;
      newSlot.provider = providerId;
      newSlot.params = params;
      newSlot.refreshCount = refreshCount;
      newSlot.creationDate = Date.now();

      // eslint-disable-next-line no-param-reassign
      container.state.slot = newSlot as Slot;

      if (!container.state.alreadyCreatedInner) {
        // eslint-disable-next-line no-param-reassign
        container.state.alreadyCreatedInner = true;
        newSlot.innerHtmlElement = setInner(containerHtmlElement, newSlot.innerId);
      } else {
        const innerHtmlElement = Html.getElementById(newSlot.innerId);
        if (!innerHtmlElement) {
          if (existingSlot) {
            removeSlots({ [containerId]: container });
          }
          containerInnerDivNotFound(containerId, pageId, slotId, newSlot, params, source);
          // eslint-disable-next-line no-param-reassign
          return false;
        }
        newSlot.innerHtmlElement = innerHtmlElement;
      }

      if (StyleV2) {
        if (existingSlot) {
          console.debug(
            `[SLOTS][CONTAINER][SET] Style.removeClass`,
            existingSlot.pageId,
            existingSlot.containerId,
            existingSlot.id
          );
          StyleV2.removeClass(
            existingSlot.innerHtmlElement,
            existingSlot.pageId,
            existingSlot.containerId,
            existingSlot.id
          );
        }
        console.debug(`[SLOTS][CONTAINER][SET] Style.addClass`, pageId, containerId, slotId);
        StyleV2.addClass(newSlot.innerHtmlElement, pageId, containerId, slotId);
      }

      const callbacks = createCallbacks(container, setSlot);
      if (!container.state.alreadySetSlot) {
        // eslint-disable-next-line no-param-reassign
        container.state.alreadySetSlot = true;
        if (LazyLoadV2 && LazyLoadV2.apply(container, callbacks, setSlot)) {
          return false;
        }
      }
      const success = ProviderV2.create(newSlot as Slot, callbacks);
      if (!success) {
        return false;
      }
    }
    return true;
  } catch (err) {
    console.error(`[SLOTS][CONTAINER][CREATE] ${container.config.id}`, err);
    newRelicMetrics.reportError(NewRelicError.CONTAINER_SET_SLOT_ERROR, { message: (err as Error).message, source });
    return false;
  }
};
