import * as Objects from 'BaxterScript/helper/object/Object';
import * as State from 'BaxterScript/version/web/core/State';
import * as Container from 'BaxterScript/version/web/core/Container';
import ConsentV2 from 'BaxterScript/version/web/feature/ConsentV2';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { ContainerType } from 'BaxterScript/types/ContainerType';
import { ContainerParams, TargetingParams } from 'BaxterScript/types/TargetingParams';
import { Config, ContainerConfig } from 'BaxterScript/types/Config';
import * as ProviderV2 from 'BaxterScript/version/web/core/ProviderV2';
import * as Cookie from 'BaxterScript/helper/browser/Cookie';
import PlaceholderV2 from 'BaxterScript/version/web/feature/PlaceholderV2';
import Cxense from 'BaxterScript/version/web/feature/Cxense';
import Gemius from 'BaxterScript/version/web/feature/Gemius';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import * as Html from 'BaxterScript/helper/browser/Html';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';
import { Features } from 'BaxterScript/version/web/config/Features';

export const webpackExclude = (config: Config): boolean => !baxterV2Enabled(config);

export const onClear = () => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONCLEAR]');
    Container.clear(State.getContainers());
    State.setContainers({});
  } catch (e) {
    console.error('[SLOTS][LIFECYCLE][ONCLEAR]', e);
    newRelicMetrics.reportError(NewRelicError.LIFECYCLE_ON_CLEAR_ERROR, { message: (e as Error).message });
  }
};

export const onPageChanged = (pageId: string, params: TargetingParams) => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONPAGECHANGED]', pageId, params);
    const prevPageId = State.getPageId();
    console.debug(`[SLOTS][LIFECYCLE][ONPAGECHANGED] PAGE CHANGED: from '${prevPageId}' to '${pageId}'`, params);
    if (pageId === prevPageId) {
      newRelicMetrics.reportMetric(NewRelicMetric.LIFECYCLE_PAGE_CHANGED_TO_THE_SAME);
    }
    onClear();
    State.setPageId(pageId);
    State.setPageParams(params);
    State.setUserId();
    State.setSessionLong();
    State.setBreakpoint();
    if (PlaceholderV2) {
      PlaceholderV2.applyToPage(pageId, params);
    }
    if (Cxense) {
      const intervalId = setInterval(() => Cxense.sendPageViewEvent(pageId), 500);
      State.setCxenseIntervalId(intervalId);
    }
    if (Gemius) {
      Gemius.gemiusHit();
    }
    ProviderV2.setPageTargeting(params);
  } catch (e) {
    console.error('[SLOTS][LIFECYCLE][ONPAGECHANGED]', e);
    newRelicMetrics.reportError(NewRelicError.LIFECYCLE_ON_PAGE_CHANGED_ERROR, { message: (e as Error).message });
  }
};

const setContainersSlots = (
  source: string,
  pageId: string,
  containers: ContainerConfig[]
): { loadable: boolean; container: ContainerType }[] =>
  containers
    .filter((container) => container.type !== 'refresh')
    .map((containerConfig) => {
      const container: ContainerType = {
        config: containerConfig,
        state: {},
      };
      return {
        container,
        loadable: Container.setSlot(
          source,
          pageId,
          container,
          Objects.clone({
            ...State.getPageParams(),
            page: pageId,
            container: containerConfig.id,
            dfp_user_id: State.getUserId(),
            sessionLong: State.getSessionLong(),
            featureFlags: Cookie.get('laquesisff')?.split('#') || [],
            abTest: Cookie.get('laquesis')?.split('#') || [],
          })
        ),
      };
    })
    .filter((containerWithLoadable) => containerWithLoadable.container.state.slot);

export const onSet = async (paramsByContainerId: ContainerParams = {}) => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONSET]', paramsByContainerId);
    if (
      ConsentV2 &&
      ConsentV2.delaySet(async () => {
        await onSet();
      })
    ) {
      return;
    }
    const pageId = State.getPageId() as string;
    const pageContainers = globalThis.Baxter.config.containers?.[pageId] || [];
    if (pageContainers.length) {
      console.debug('[SLOTS][LIFECYCLE][ONSET] setContainerSlot', paramsByContainerId, pageContainers);
      const containersWithLoadable = setContainersSlots(Features.NON_LAZY_LOAD, pageId, pageContainers);
      const containersByIds = containersWithLoadable.reduce((result, containerWithLoadable) => {
        // eslint-disable-next-line no-param-reassign
        result[containerWithLoadable.container.config.id] = containerWithLoadable.container;
        return result;
      }, {});
      State.setContainers(containersByIds);
      console.debug('[SLOTS][LIFECYCLE][ONSETAFTERLOADED] onload');
      const slotsToLoad = containersWithLoadable
        .filter((containerWithLoadable) => containerWithLoadable.loadable)
        .map((containerWithLoadable) => containerWithLoadable.container.state.slot!);
      await ProviderV2.load(Features.NON_LAZY_LOAD, slotsToLoad);
      setTimeout(() => {
        slotsToLoad.forEach((slot) => {
          if (!Html.getElementById(slot.innerId)) {
            newRelicMetrics.reportError(NewRelicError.LIFECYCLE_SLOT_DISAPPEARED, {
              pageId: slot.pageId,
              containerId: slot.containerId,
              slotId: slot.id,
            });
          }
        });
      }, 100);
    } else {
      console.error('[SLOTS][LIFECYCLE][ONSET] NO CONTAINERS FOUND FOR PAGE');
      newRelicMetrics.reportError(NewRelicError.LIFECYCLE_NO_CONTAINERS_FOUND_FOR_PAGE, { pageId });
    }
  } catch (error) {
    console.error('[SLOTS][LIFECYCLE][ONSET]', error);
    newRelicMetrics.reportError(NewRelicError.LIFECYCLE_ON_SET_ERROR, { message: (error as Error).message });
  }
};

export const onSetPageParams = (params: TargetingParams) => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONSETPAGEPARAMS]', params);
    State.setPageParams(params);
  } catch (e) {
    console.error('[SLOTS][LIFECYCLE][ONSETPAGEPARAMS]', e);
    newRelicMetrics.reportError(NewRelicError.LIFECYCLE_ON_PAGE_PARAMS_ERROR, { message: (e as Error).message });
    throw e;
  }
};

export const onPageTrack = async () => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONPAGETRACK]');
    State.getPageTrackers().forEach((tracker) => tracker());
  } catch (e) {
    console.error('[SLOTS][LIFECYCLE][ONPAGETRACK]', e);
    newRelicMetrics.reportError(NewRelicError.LIFECYCLE_ON_PAGE_TRACK_ERROR, { message: (e as Error).message });
  }
};
